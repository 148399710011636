@charset "utf-8";

$blue-100: #E2ECFF;
$blue-200: #8DB1F5;
$blue-300: #5990F6;
$blue-400: #236BF3;
$blue-500: #1B4CAA;
$blue-600: #0B1C3E;

$light-yellow: #FFF9ED;
$yellow: #E9A932;
$dark-yellow: #D06A0F;

$light-green: #E3F5E4;
$green: #45B74C;
$dark-green: #26622B;

$secondary-100: #F57C00;

$light-red: #F7E9E8;
$red: #EB3931;
$dark-red: #942016;

$white: #FFFFFF;

$grey-100: #F8F8FA;
$grey-200: #EDEEF4;
$grey-300: #C7C9D0;
$grey-400: #93969C;
$grey-500: #585961;
$grey-600: #2C2F35;
$grey-700: #01030D;

$black: #01030D;

//dark mode colors
$dark-mode-100: #F8F8FA;
$dark-mode-200: #C3C5CC;
$dark-mode-300: #383E4D;
$dark-mode-400: #303542;
$dark-mode-500: #262B38;
$dark-mode-500-60per: #222633;
$dark-mode-600: #1C1F2A;

$dark-mode-primary: #4874C7;
$dark-mode-info: #354C7C;
$dark-mode-toast-error: #7D2A2F;
$dark-mode-error: rgba(250, 62, 52, 0.65);
$dark-mode-warning: #946D2A;
$dark-mode-success: #2D5C38;

$themes: (
    dark: (
        header-background-color: $dark-mode-400,
        navbar-header-color: $dark-mode-100,
        header-color: $dark-mode-100,
        label-color: $dark-mode-100,
        has-button-border-color: false,
        theme-button-icon-color: $white,
        body-background-color: $dark-mode-600,

        side-nav-background-color: $dark-mode-500-60per,
        side-nav-color: $dark-mode-200,
        side-nav-hover-background-color: $dark-mode-300,
        side-nav-active-background-color: $dark-mode-300,
        side-nav-active-color: $dark-mode-100,

        footer-background-color: $dark-mode-600,
        footer-color: $dark-mode-200,

        modal-card-head-background-color: $dark-mode-info,
        modal-card-head-color: $dark-mode-100,
        modal-background-color: $dark-mode-500,
        modal-label-color: $dark-mode-100,
        modal-content-color: $dark-mode-200,
        modal-blurred-background-color: $blue-600,

        card-header-background-color: $dark-mode-600,
        card-header-title-color: $blue-100,
        card-header-subtitle-color: $grey-400,
        card-content-background-color: $grey-500,

        table-border-color: $dark-mode-300,
        table-header-background-color: $dark-mode-300,
        table-body-background-selected-color: $blue-400,
        table-header-color: $dark-mode-200,
        table-header-border-color: $dark-mode-400,
        table-body-background-color: $dark-mode-500,
        table-body-stripe-color: $dark-mode-400,
        table-body-first-color: $dark-mode-100,
        table-body-color: $dark-mode-200,
        table-body-hover-background-color: $dark-mode-300,
        table-body-hover-color: red,
        table-card-header-background-color: $dark-mode-600,
        table-card-content-background-color: $dark-mode-600,
        list-container-header-color: $grey-100,
        embed-card-header-background-color: $dark-mode-300,


        text-box-background-color: $dark-mode-600,
        text-box-active-background-color: $black,
        text-box-color: $dark-mode-100,
        text-box-placeholder-color: $grey-500,
        text-box-active-color: $dark-mode-100,

        input-border-color: $dark-mode-200,

        toast-error-background-color: $dark-mode-toast-error,
        toast-error-color: $white,
        toast-error-background-image: 'public/error-light.svg',
        toast-info-background-color: $dark-mode-info,
        toast-info-color: $white,
        toast-info-background-image: 'public/info-light.svg',
        toast-success-background-color: $dark-mode-success,
        toast-success-color: $white,
        toast-success-background-image: 'public/success-light.svg',

        button-background-color: $dark-mode-primary,
        button-border-color: $dark-mode-primary,
        button-color: $dark-mode-100,
        button-hover-background-color: $blue-500,
        button-hover-border-color: $blue-500,
        button-disabled-color: $dark-mode-100,

        button-secondary-border-color: $blue-300,
        button-secondary-color: $blue-300,
        button-secondary-hover-background-color: $dark-mode-300,
        button-secondary-disabled-border-color: $blue-300,
        button-secondary-disabled-color: $blue-300,

        button-neutral-border-color: $dark-mode-100,
        button-neutral-color: $dark-mode-100,

        button-danger-border-color: #b03736,

        react-select-multi-value-background-color: $grey-500,
        react-select-option-background-color: $dark-mode-600,
        react-select-option-hover-background-color: $grey-500,
        react-select-option-color: $dark-mode-200,

        tool-menu-background-color: $dark-mode-600,
        tool-menu-color: $dark-mode-200,
        tool-menu-hover-background-color: $grey-500,
        tool-menu-hover-color: $dark-mode-100,

        react-menu__button--background-color: $dark-mode-600,
        react-menu__button--border-color: $white,
        react-menu__button--color: $dark-mode-100,
        react-menu__button--hover-color: $grey-300,

        p-color: $dark-mode-200,
        ul-color: $dark-mode-200,
        primary: $dark-mode-primary,
        info: $dark-mode-info,
        secondary: $secondary-100,
        warning: $dark-mode-warning,
        success: $dark-mode-success,
        danger: $dark-mode-error,
        light: $dark-mode-300,
        link: $blue-300,
        link-hover: $dark-mode-200,
    ),
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), '#{$key}');
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

$primary: $blue-400;
$info: $blue-200;
$secondary: $secondary-100;

$warning: $yellow;
$success: $green;
$danger: $red;

$background: $grey-100;

$navbar-background-color: $white;
$navbar-footer-color: $grey-100;

$th-border-color: $grey-300;

$button-text-color: $white;
$button-background-color: $blue-400;
$button-active-color: $white;
$button-hover-color: $white;
$button-color: $white;

$full-page-content: calc(100vh - 7.5rem); 