@import "PLT-variables.scss";

@import "https://fonts.googleapis.com/css?family=Work+Sans";

/*
    This file contains all the styles that will be applied for the PLT themed
    Styles are based on the elements and examples from the Figma file provided from SnapPea
    Colours should not directly declared here (unless basic color like white or black), instead edit all colours in the PLT-variables.css file

    If the themeing is not working, ensure that the `html` tag has the id `app-container` in the index.html file


*/


//Work around to make the entire page the correct background color
//No idea why targeting the html tag doesn't work with the themeify mixin but it don't

.theme-dark {
    background-color: $dark-mode-600;
    hr {
        background-color: $white;
    }
}

.navbar {
    @include themify($themes) {
        background-color: themed('header-background-color');
    }
    span {
        color: $white;
    }
}

p {
    @include themify($themes) {
        color: themed('p-color');
    }
}

a {
    @include themify($themes) {
        color: themed('link');
        &:hover {
            color: themed('link-hover')
        }
    }
}

ul {
    @include themify($themes) {
        color: themed('ul-color');
    }
}

.label,
.title {
    @include themify($themes) {
        color: themed('header-color');
    }
}

#footer-level {
    @include themify($themes) {
        background-color: themed('footer-background-color');
        strong, p {
            color: themed('footer-color');
        }
    }
}

.main-div {
    @include themify($themes) {
        background-color: themed('body-background-color');
    }
}

.side-menu {
    @include themify($themes) {
        .theme-button-container {
            position: absolute;

            .button {
                border: none;
                color: themed('theme-button-icon-color');
            }
        }

        height: $full-page-content;
        background-color: themed('side-nav-background-color');

        .panel-block {
            color: themed('side-nav-color');
            border-radius: 4px;
            border: none;

            .panel-icon {
                color: themed('side-nav-color');
            }
        }

        .panel-block:hover {
            background-color: themed('side-nav-hover-background-color');
            border-radius: 4px;
        }

        padding: 18px 8px 0px;
        justify-content: space-between;

        .panel-block.is-active {
            background-color: themed('side-nav-active-background-color');
            color: themed('side-nav-active-color');

            .panel-icon {
                color: themed('side-nav-active-color');
            }
        }
    }
}

.table-container {
    @include themify($themes) {


        .table {
            border: 0.5px solid themed('table-border-color');
            border-radius: 10px;
        }


        .table thead {
            tr td:not(:last-child) {
                border-right: 1px solid themed('table-header-border-color');
                box-shadow: 0px 3px 8px rgba(0,0,0,0.15);
            }

            font-family: Work Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height */

            letter-spacing: 0.05em;
            text-transform: uppercase;
            background-color: themed('table-header-background-color');

            td {
                color: themed('table-header-color');
                border-bottom: none;
                padding: 10px 10px 10px 24px;
                min-width: fit-content;
            }
        }

        .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(n) {
            background-color: themed('table-body-hover-background-color');
            .td {
                color: themed('table-body-hover-color');
            }
        }

        .table.is-striped tbody tr:not(.is-selected):nth-child(2n) {
            background-color: themed('table-body-stripe-color');
        }

        .table tbody tr.is-selected {
            background-color: themed('table-body-background-selected-color');
        }

        .table tbody tr {
            background-color: themed('table-body-background-color');

            //height: 2.75rem;
            align-content: center;

            td:first-child {
                border: none;
                font-family: Work Sans;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: themed('table-body-first-color');
                vertical-align: middle;
                padding: 24px 10px 24px 24px;
            }

            td {
                border: none;
                font-family: Work Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: themed('table-body-color');
                vertical-align: middle;
                padding: 24px 10px 24px 24px;
            }
        }
        border-radius: 4px 4px 4px 4px;
    }
}

.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include themify($themes) {
        background-color: themed('card-header-background-color');
    }
}

.card-header-title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;

    letter-spacing: -0.02em;
    @include themify($themes) {
        color: themed('card-header-title-color');
    }
}

.card-header-subtitle {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 19px;
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0rem 0.75rem 1rem 1rem;

    letter-spacing: -0.02em;
    @include themify($themes) {
        color: themed('card-header-subtitle-color');
    }

}

.card-header-container * {
    margin-bottom: 0 !important;
}

.card-content {
    @include themify($themes) {
        background-color: themed('card-content-background-color')
    }
}

.label {
    @include themify($themes) {
        color: themed('label-color');
    }
}

.navbar-item {
    @include themify($themes) {
        .title {
            color: themed('navbar-header-color');
        }
    }
}

#header {
    box-shadow: 2px 5px 10px rgba(44, 50, 61, 0.08);


    @include themify($themes) {
        background-color: themed('header-background-color');
    }
}

.modal-card {
    @include themify($themes) {
        .modal-card-head {
            border-bottom: unset;
            background-color: themed('modal-card-head-background-color');

            color: themed('modal-card-head-color');

            .modal-card-title {
                font-family: Work Sans;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;
                letter-spacing: -0.02em;

                color: themed('modal-card-head-color');
            }
        }

        .modal-card-body {
            display: flex;
            flex-direction: column;
            min-height: 0px;
            background-color: themed('modal-background-color');
            color: themed('modal-content-color');
            p {
                font-family: Work Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: -0.02em;

                color: themed('modal-content-color');

                .field-label {
                    font-family: Work Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 14px;
                    letter-spacing: -0.02em;
                    padding-bottom: 0.5em;

                    color: themed('modal-label-color');
                }
            }
            hr {
                background-color: themed('modal-content-color');
            }

            .note-field {
                padding-bottom: 1.5em;
            }

            .field {
                min-height: 3em;
            }

            .control {
                overflow: auto;
                height: 100%;
            }
        }

        .modal-card-foot {
            background-color: themed('modal-background-color');
            border-top: unset;
        }
    }
}

.modal-background {
    @include themify($themes) {
        background-color: themed('modal-blurred-background-color');
        opacity: 0.35;
        filter: blur(4px);
    }
}

.embed-card {
    @include themify($themes) {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: rgba(0,0,0,0);

        .card-header {
            background-color: themed('embed-card-header-background-color');
            flex-grow: 0;
        }
        .card-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            background-color: themed('modal-background-color');
            .field-label {
                color: themed('modal-label-color');
            }
            color: themed('modal-content-color');
        }
        .card-footer {
            background-color: themed('modal-background-color');
            border-top: unset;
            justify-content: flex-end;
            padding: 20px;
            .button:not(:last-child) {
                margin-right: 0.5em;
            }
            .button {
                .fas {
                    margin-right: 0.5em;
                }
            }
        }
        .table-container {
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

.button {
    @include themify($themes) {
        background-color: themed('button-background-color');
        border-color: themed('button-border-color');
        color: $white;
        border-radius: 4px !important;

        &.is-wide {
            width: 105px;
        }

        &.is-small-wide {
            width: 97px;
        }

        &.is-active,
        &:active {
            box-shadow: inset 1px 1px 4px rgba($grey-darker, 0.3);
        }

        &.is-hovered,
        &:hover {
            color: $white;
            background-color: themed('button-hover-background-color');
            border-color: themed('button-hover-border-color');
        }

        &[disabled],
        fieldset[disabled] & {
            opacity: 0.38;
            color: themed('button-disabled-color');
        }

        &.is-secondary {
            background-color: transparent;
            border-color: themed('button-secondary-border-color');
            color: themed('button-secondary-color');

            &:hover,
            &.is-hovered,
            &:focus,
            &.is-focused {
                background-color: themed('button-secondary-hover-background-color');
            }

            &[disabled],
            fieldset[disabled] & {
                opacity: 0.38;
                border-color: themed('button-secondary-disabled-border-color');
                box-shadow: none;
                color: themed('button-secondary-disabled-color');
            }
        }

        &.is-neutral {
            background-color: transparent;
            border-color: themed('button-neutral-border-color');
            color: themed('button-neutral-color');
        }

        &.is-danger {
            background-color: themed('danger');
            border-color: themed('button-danger-border-color') !important;
        }

        @each $name, $pair in $colors {
            $color: nth($pair, 1);
            $color-invert: nth($pair, 2);


            &.is-#{$name} {
                background-color: themed($name);
                border-color: themed($name);
                color: themed('button-color');

                &.is-hovered,
                &:hover {
                    background-color: darken($color, 15);
                    border-color: transparent !important;
                    color: $color-invert;
                }

                &.is-active,
                &:active {
                    color: themed('button-color');
                    //box-shadow: themed('button-box-shadow');
                    background-color: darken($color, 10);
                }

                &.is-outlined {
                    background-color: transparent;
                    border-color: $color;
                    color: $color;

                    &:hover,
                    &.is-hovered,
                    &:focus,
                    &.is-focused {
                        background-color: themed($name);
                        color: themed('button-color');
                    }

                    &.is-loading &::after {
                        @if themed('has-button-border-color') {
                            border-color: transparent transparent $color $color !important;
                        }
                    }

                    &:hover, &.is-hovered, &:focus, &.is-focused &::after {
                        @if themed('has-button-border-color') {
                            border-color: transparent transparent $color-invert $color-invert !important;
                        }
                        @else {
                            border-color: transparent transparent themed($name) themed($name) !important;
                        }
                    }

                    &[disabled],
                    fieldset[disabled] & {
                        background-color: transparent;
                        border-color: $color;
                        box-shadow: none;
                        color: $color;
                        opacity: 0.38;
                    }
                }
            }
        }
    }
}

.button.is-outlined {
    background-color: transparent;
}

#toast-container {
    @include themify($themes) {
        .toast {
            opacity: 1;
        }

        .toast-close-button {
            color: themed('button-background-color');
            text-shadow: none;
        }

        border-radius: 4px;
        .toast-info {
            color: themed('toast-info-color');
            background-color: themed('toast-info-background-color');
            background-image: url(themed('toast-info-background-image')) !important;
        }

        .toast-success {
            color: themed('toast-success-color');
            background-color: themed('toast-success-background-color');
            background-image: url(themed('toast-success-background-image')) !important;
        }

        .toast-error {
            color: themed('toast-error-color');
            background-color: themed('toast-error-background-color');
            background-image: url(themed('toast-error-background-image')) !important;
        }
    }
}

.list-container-header {
    @include themify($themes) {
        color: themed('list-container-header-color');
    }
}

.input:disabled {
    opacity: 0.38;
    box-shadow: none;
}

.input, .textarea {
    @include themify($themes) {
        background-color: themed('text-box-background-color');
        color: themed('text-box-color');
        border: 1px solid themed('input-border-color');
        border-radius: 4px;
    }
}

.input:focus, .textarea:focus {
    @include themify($themes) {
        background-color: themed('text-box-active-background-color');
        color: themed('text-box-active-color');
    }
}

::placeholder {
    @include themify($themes) {
        color: themed('text-box-placeholder-color');
    }
}

[id*=SearchBarInput] {
    @include themify($themes) {
        z-index: 1;
        border: 1px solid white;
        &::placeholder {
            color: themed('text-box-color');
        }
    }
}

[id*=SearchBarCancelButton] {
    @include themify($themes) {
        .icon {
            color: themed('body-background-color');
        }
    }
}

table {
    tbody tr td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

//BEGIN ReactSelect Styling

.react-select__control {
    @include themify($themes) {
        background-color: themed('text-box-background-color');
    }
}

.react-select__multi-value {
    @include themify($themes) {
        background-color: themed('react-select-multi-value-background-color');
    }
}

.react-select__single-value,
.react-select__multi-value__label {
    @include themify($themes) {
        color: themed('text-box-color');
    }
}
.react-select__menu,
.react-select__option {
    @include themify($themes) {
        background-color: themed('react-select-option-background-color');
        color: themed('react-select-option-color');
    }
}

.react-select__option--is-focused {
    @include themify($themes) {
        background-color: themed('react-select-option-hover-background-color');
    }
}

.react-select--is-disabled {
    opacity: 0.38;
    box-shadow: none;
}

.react-select__indicator,
.react-select__indicators,
.react-select__value-container {
    border-radius: 4px;
}


//END ReactSelect Styling

.table-card {
    @include themify($themes) {

        .card-content {
            border-radius: 0px;
            background-color: themed('table-card-content-background-color');
        }

        .card-header {
            border-radius: 0px;
            background-color: themed('table-card-header-background-color');
        }
    }
}

.profile-image-column {
    min-width: calc(64px + 4em);
    max-width: calc(64px + 4em);
    padding-left: 0;
    padding-right: 0;
}

.tele-nav {
    justify-content: center;
    color: grey;
    margin: 0;
}

.navbar-item:first-child {
    margin-right: auto;
}

.navbar-item:last-child {
    margin-left: auto;
}

.no-margin {
    margin: 0 !important;
}

.tele-nav-string {
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    img {
        margin-right: 10px;
    }
}

.tele-nav-label {
    color: white;
    border-width: 1px;
    justify-content: center;
    text-align: center;
    padding: calc(0.5em - 1px) 1em calc(0.5em - 1px) 1em;
    background-color: $grey-500;
    border-radius: 4px;
    display: flex;
    margin-right: 10px;

    &.connected {
        background-color: $green
    }

    &.connecting, &.paused {
        background-color: $yellow
    }

    &.failed {
        background-color: $red
    }

    &.stopped {
        background-color: $dark-yellow
    }
}

.tele-join-button {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .button {
        width: 20%;
    }
}

.webrtc-container {
    position: relative;
    display: grid;
    height: 100%;

    #viewer {
        // Need to set overflow to "hidden" because of an annoying quirk of CSS where it will ignore any non-absolute sizing changes that cause
        // the contents of an element to overflow. We want to force it because VTK will automatically resize the canvas to fit this container.
        overflow: hidden;
        width: 100%;
        max-width: 80vh;
        max-height: 80vh;
        margin: auto;
        margin-top: unset;
    }
}

.webrtc-overlay {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 100%;
    max-width: 80vh;
    max-height: 80vh;
    margin: auto;
    margin-top: unset;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);

    &.receiving-images {
        display: none;
    }
}

.webrtc-overlay-label {
    width: 100%;
    font-size: clamp(1rem, 1vw + 1vh, 3rem);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $white
}

div {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;

        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 2.5em);
            left: calc(50% - 2.5em);
            width: 5em;
            height: 5em;
            border-width: 0.25em;
            z-index: 2;
        }
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
        box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
        box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0);
        box-shadow: 0 0 0 0 rgba(69, 183, 76, 0);
    }
}

.pulse {
    box-shadow: 0 0 0 rgba(69, 183, 76, 0.6);
    animation-name: pulse;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

@-webkit-keyframes pulse2 {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0);
    }
}

@keyframes pulse2 {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
        box-shadow: 0 0 0 0 rgba(69, 183, 76, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
        box-shadow: 0 0 0 10px rgba(69, 183, 76, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(69, 183, 76, 0);
        box-shadow: 0 0 0 0 rgba(69, 183, 76, 0)
    }
}

.pulse2 {
    box-shadow: 0 0 0 rgba(69, 183, 76, 0.6);
    animation-name: pulse2;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

@keyframes no-animation {
    0% {}
    100% {}
}

.cancel-pulse {
    animation-name: no-animation !important;
    animation-duration: 0.01s;
}

.historical-column {
    display: flex;
    flex-direction: column;
}

.historical-container {
    position: relative;
    display: flex;
    flex-direction: column;

    &.row-single {
        height: 100%;
        width: 100%;
    }

    &.row-double {
        height: 50%;
        width: 50%;
        padding: 0 1rem;
    }

    &.row-triple {
        height: 50%;
        width: 33.3%;
        padding: 0 1rem;
    }

    &.tall {
        height: 100% !important;
    }

    #viewer {
        // Need to set overflow to "hidden" because of an annoying quirk of CSS where it will ignore any non-absolute sizing changes that cause
        // the contents of an element to overflow. We want to force it because VTK will automatically resize the canvas to fit this container.
        overflow: hidden;
        width: 100%;
        margin-top: unset;
    }
}

.historical-view-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: auto;
}

.historical-container--hidden {
    display: none;
}

.historical-container--selected {
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
}

.historical-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    //overflow: auto; // REVIEW: This was cutting off the very top of the slider - is it necessary?
}

.historical-timeline-container {
    float: none;
    // The thumbnails load after the canvas meaning the height of this is 0 initially
    // Because of this, if we don't add a flex-basis it will cause issues with the canvas rendering
    // The value is just the sum of the height of a thumbnail plus the margins
    flex-basis: 11.6vh;
}

.multi-historical-footer {
    margin: 0.5rem;
    display: flex;

    .field {
        width: 50%;
        margin-right: 1rem;
    }
}

.historical-timeline {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;

    a {
        flex: 0 0 auto;
        min-width: 0;
        display: grid;
        grid-template-areas: "overlap";
    }

    img:not(.frame-flag) {
        grid-area: overlap;
        height: 10vh;
        margin: 0.8vh 0 0.8vh 0.8vh;
        min-width: 0;
    }

    img:not(.frame-flag):not(.selected-frame) {
        padding: 5px;
    }

    .selected-frame {
        border: 5px solid rgba(69, 183, 76, 0.6);
    }
}

.frame-flag {
    grid-area: overlap;
    z-index: 1;
    background-color: rgba(69, 183, 76, 1);
    margin: 1vh;
    height: 2vh;
}

.multi-historical-timeline-container {
    display: inline;
    margin-bottom: 0.75rem;
    width: 50%;
}

.multi-historical-timeline {
    padding: 4px;
    display: flex;
    flex-direction: column;

    a {
        flex: 0 0 auto;
        min-width: 0;
        display: grid;
        grid-template-areas: "overlap";
    }

    .selected-frame {
        position: relative;
    }

    .selected-frame img:not(.multi-frame-flag) {
        outline: 4px solid rgba(69, 183, 76, 0.6);
        box-sizing: border-box;
    }

    .unselected-frame {
        position: relative;
    }
}

.multi-frame-flag {
    grid-area: overlap;
    z-index: 1;
    background-color: rgba(69, 183, 76, 1);
    margin: 0.25em;
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
}

.full-page-content {
    height: $full-page-content;
}

.half-page-content {
    height: calc($full-page-content / 2);
}

.szh-menu-container .szh-menu {
    z-index: 2;
    list-style: none;
    @include themify($themes) {
        background-color: themed('tool-menu-background-color');

        &__item {
            color: themed('tool-menu-color');
            &--hover {
                background-color: themed('tool-menu-hover-background-color');
                color: themed('tool-menu-hover-color');
            }
        }
    }
}

.react-menu__button {
    width: 100%;
    min-height: 38px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 0;

    &__text {
        flex: 1;
        text-align: left;
        font-size: 1rem;
        padding: 2px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    &__indicator {
        padding: 8px;

        &::before {
            display: inline-block;
            width: 20px;
        }

        &__container {
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }

        &__separator {
            align-self: stretch;
            background-color: hsl(0, 0%, 80%);
            margin-bottom: 8px;
            margin-top: 8px;
            width: 1px;
            height: 1.5em;
            box-sizing: border-box;
        }
    }

    @include themify($themes) {
        background-color: themed('react-menu__button--background-color');
        border-color: themed('react-menu__button--border-color');
        color: themed('react-menu__button--color');
    }
}

.react-menu__button__indicator--open:hover {
    @include themify($themes) {
        color: themed('react-menu__button__indicator--hover-open-color');
    }
}

.react-menu__button:hover {
    @include themify($themes) {
        background-color: themed('react-menu__button--background-color');
        border-color: themed('react-menu__button--hover-color');
    }
}

.react-menu__button__indicator:hover {
    @include themify($themes) {
        color: themed('react-menu__button--hover-color');
    }
}

.react-menu__button__container {
    width: 100%;
}

// Only for chrome, firefox date picker looks fine
input[type="date"]::-webkit-calendar-picker-indicator {
    /*! /* webpackIgnore: true */
    background: url(calendar-light.svg) no-repeat;
    cursor: pointer;
    width: 0.8em;
    height: 0.8em;
    margin-top: -0.1rem;
    margin-left: -1rem;
}

.scheduled-card-container {
    @include themify($themes) {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        max-width: 100%;
        position: relative;
        inset: auto 0;
        margin: 0 0.5rem;

        .card-header-title {
            margin-bottom: 0;
        }

        .card {
            display: inline-block;
            margin: 0.5rem 0.5rem;
        }
    }
}

h1 {
    @include themify($themes) {
        color: themed('header-color');
    }
}

h2 {
    @include themify($themes) {
        color: themed('header-color');
    }
}

h3 {
    @include themify($themes) {
        color: themed('header-color');
    }
}

.sortable-table-item {
    cursor: pointer;
}

.hover-link {
    @include themify($themes) {
        color: themed('link');
        text-decoration: underline;
    }
}

.hover-link:hover, .sortable-table-item:hover .hover-link {
    @include themify($themes) {
        color: themed('link-hover');
    }
}

.share-link {
    display: inline-block;
    width: 100%;
    height: 1em;
}

.share-link--enabled {
    /*! /* webpackIgnore: true */
    background: url(share-light.svg) no-repeat center;
    cursor: pointer;
}

.share-link--disabled {
    text-align: center;
    cursor: not-allowed;
}

.share-link--enabled:hover, .sortable-table-item:hover .share-link--enabled {
    /*! /* webpackIgnore: true */
    background: url(share-hover.svg) no-repeat center;
}

.patient-details-columns, .patient-details-column {
    height: 100%;
}

.patient-details-column, .session-details-column {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    table {
        background-color: rgba(0,0,0,0);
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;

        tbody {
            overflow-y: auto;
            overflow-x: hidden;
        }

        thead, tbody {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        tr {
            display: flex;
            justify-content: flex-start;
        }

        td {
            flex: 1;
            flex-wrap: nowrap;
        }
    }
}

#SessionTable {
    tr > *:last-child {
        overflow: visible;
    }
}

#FlaggedImagesTable {
    tr > *:first-child{
        flex: 0 0 80px;
    }
}

//., .patients-notes-card, .{
//    flex: 1;
//    display: flex;
//    flex-direction: column;
//    overflow: hidden;
//    background-color: rgba(0,0,0,0);

//    .card-header {
//        flex-grow: 0;
//    }

//    .card-content {
//        flex: 1;
//        display: flex;
//        flex-direction: column;
//        overflow: hidden;
//    }

//    .table-container {
//        display: flex;
//        flex-direction: column;
//        overflow: hidden;
//    }
//}

#Patient\ Search {
    overflow-y: hidden;
}

html {
    overflow: auto;
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: $dark-mode-200 $dark-mode-600
  }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: $dark-mode-600;
}

::-webkit-scrollbar-thumb {
    background-color: $dark-mode-200;
    border-radius: 8px;
    border: 2px solid $dark-mode-600;
}

::-webkit-scrollbar-thumb:hover {
    background: $dark-mode-300;
}

.save-spinner {
    color: white;
    position: relative;
    top: 0;
    left: 2em;
    z-index: 1;
    height: 0px;
    width: 0px;

    &:after {
        @include loader;
        position: absolute;
        top: 0.1em;
        left: -1.5em;
        width: 1em;
        height: 1em;
        border-width: 0.25em;
    }
}

.sequence-menu__label {
    display: flex;
    justify-content: flex-start;
}

.sequence-menu__label__item {
    flex: 1;
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    padding-right: 5px;
    border-right: 1px dashed $grey-300;
}

.sequence-menu__label__item:last-child {
    border-right: none;
}

.historical-container__close-button {
    border: none;
    padding: 0;
    position: relative;
    top: 2em;
    left: calc(100% - 2em);
    z-index: 1;
    height: 0px;
    width: 0px;
}

.historical-container__close-button-icon {
    opacity: 0.5;
}

.historical-container__close-button-icon:hover {
    opacity: 1;
}

.column--scrollable {
    height: 100%;
    overflow-y: scroll;
}

.assessments-card__content {
    display: flex;
    flex-direction:column;
}

.assessments-card__section {
    flex: 1;
}

.assessments-card__section:not(:last-child) {
    margin-bottom: 1em;
}

.assessments-card__section-header {
    font-weight: 900;
    color: $white;
}

.assessments-card__section-content {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-container {
    display: flex;
}

.historical-container__viewport-select-button {
    border-color: $white !important;
    background-color: rgba(0,0,0,0.5) !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 41;
    // text
    font-size: 8em;
    font-weight: bold;
}

.historical-container__viewport-select-button:hover {
    background-color: rgba(0,0,0,0) !important;
    border-color: $blue-300 !important;
    border-width: 2px;
    // text
    color: $blue-300 !important;
}

#FlaggedImagesTableBody, #ReportsTableBody {
    .sortable-table-item {
        cursor: default;
    }
}

.image-preview-button:hover {
    color: $white;
    border: 3px $info;
    border-style: solid;
    padding: 0 1px 0 1px;
}
