@charset "utf-8";

// FontAwesome
$fa_font_path: "~@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';

$family-primary: 'Work Sans';

//Variables
$mobile: 500px;

// Bulma

@import "PLT-variables.scss";

@import "~bulma/bulma";
@import "~bulma/sass/utilities/mixins";

@import "@szhsin/react-menu/dist/index.css";

@import "PLT-style.scss";

* {
    box-sizing: border-box;
}

ul {
    padding-left: 2em;
}

html, body {
    height: 100vh;
}

body {
    padding-bottom: $navbar-height;
}

.field-label {
    text-align: left;
    font-weight: bold;
}

.modal-card-title {
    flex-shrink: 1;
}

.modal {
    overflow: auto;
    background-color: rgba(10, 10, 10, 0.86);
}

.modal-card {
    overflow: unset;
    @media screen and (max-width: $tablet) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.modal-card-body {
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: unset;
}

.modal-background {
    background-color: rgba(0, 0, 0, 0);
}

.modal-card-foot {
    justify-content: flex-end;
}

.container.is-fluid {
    padding: 16px;
}

#footer-touch {
    height: calc($navbar-height / 2);
    min-height: unset;
    z-index: auto;
}

#footer-desktop {
    @media screen and (max-height: 500px) {
        position: unset;
    }
    z-index: auto;
}

#footer-level {
    flex-grow: 1;
    background-color: $footer-background-color;
    color: $footer-color;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media screen and (max-width: $mobile) {
    #nav-title {
        font-size: 1.5rem;
    }
}

// Tooltip container
.tooltip {
    position: relative;
    display: inline-block;
}

// Tooltip text
.tooltip .tooltip-text {
    visibility: hidden;
    width: 14rem;
    background: rgba(74,74,74,0.9);
    color: #fff;
    text-align: center;
    padding: 0.3rem 0.7rem;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 50%;
    margin-left: -7rem; // Use half of the width to center the tooltip
    text-wrap: wrap;
}

// Show the tooltip text when you mouse over the tooltip container
.tooltip:hover .tooltip-text {
    visibility: visible;
}

// Tooltip arrow
.tooltip .tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(74,74,74,0.9) transparent transparent transparent;
}

.card {
    border-radius: 0.5rem;
}

.card-header {
    @media screen and (max-width: $mobile) {
        display: inherit;
    }
}

.card-header-title {
    @media screen and (max-width: $mobile) {
        justify-content: center;
    }
}

.side-nav-theme-toggle {
    align-self: flex-end;
}

.list-container-header {
    margin-right: auto;
    align-self: end;
}

// [BEGIN] File upload component
.file-name {
    max-width: 30em;
}

.file-layout {
    flex-direction: column;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    max-width: 30em;
}
// [END] File upload component

.field {
    &.is-loading {
        position: relative;
        pointer-events: none;
        opacity: 0.5;
        &:after {
            @include loader;
            position: absolute;
            top: calc(50% - 0.1em);
            left: calc(50% - 0.9em);
            width: 1.8em;
            height: 1.8em;
            border-width: 0.25em;
        }
    }
}

.content {
    .navmenu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        justify-content: center;
    }

    .navmenu-item {
        float: left;
        margin-right: 10px;
        display: block;
        text-align: center;
    }
}
